import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const SplitContentCss = withTheme(() => (
  <Global
    styles={css`
      ${'' /* Styles go here */}
    `}
  />
))

export default SplitContentCss
