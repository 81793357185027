import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const DualStickyCTAStyles = withTheme(() => (
  <Global
    styles={css`
      .sticky-cta,
      .dual-sticky-cta {
      }
    `}
  />
))
export default DualStickyCTAStyles
