import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const StateDropdownStyles = withTheme(({ theme }) => (
  <Global
    styles={css`
      .state-dropdown {
        svg {
          path {
            color: ${theme.colors.primary.base.hex};
          }
        }

        /* guys, this is one of the wildest CSS selectors I've ever had to use, 
        but it was necessary to make sure the bottom two options in the state dropdown menu 
        didn't get cut off. like, seriously. i tried EVERYTHING. maybe (probably) one of you 
        can come up with something better in the future, but this was the best I could do. */
        div:has(div[aria-disabled='false']) {
          position: relative;
          z-index: 9 !important;
          overflow: scroll;
        }

        div:has(div[aria-disabled='false']):hover {
          position: relative;
          z-index: 9 !important;
          overflow: scroll;
        }
      }
    `}
  />
))

export default StateDropdownStyles
