import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const HeaderStyles = withTheme(() => (
  <Global
    styles={css`
      .top-bar {
        justify-content: center !important;
        text-align: center;
      }
    `}
  />
))

export default HeaderStyles
