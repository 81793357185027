import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const FooterStyles = withTheme(({ theme }) => (
  <Global
    styles={css`
      .leshen-footer {
        border-top: 5px solid ${theme.colors.primary.base.hex};

        .legal-section {
          //Brandy tags inside of tags be wild, ya'll//
          p,
          span {
            color: ${theme.colors.dark.base.hex} !important;
            font-size: 12px !important;
            opacity: 0.8 !important;
          }

          p {
            span {
              p {
                color: ${theme.colors.dark.base.hex} !important;
                font-size: 12px !important;
                opacity: 1 !important;
              }
            }
          }
        }
      }
    `}
  />
))

export default FooterStyles
